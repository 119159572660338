import { useSelector } from 'react-redux';
import moneyFormat from 'helpers/moneyFormat';
import Button from 'shared/components/Button/Button';
import { selectPaymentMades } from 'features/payment/Payment.slice';
import { Table } from 'antd';
import { TableDesktopWrraper } from '../Payment.styles';
import { PaymentMade as PaymentMadeModel } from 'shared/models/payment-made.model';
import { ColumnsType } from 'antd/es/table';
import { toShortTimezoneBySiteString } from 'helpers/dateFormats';
import { ReactComponent as EyeIcon } from 'shared/icons/eye.svg';
import { selectOrderLine } from 'features/order/Order.slice';
import { useEffect, useRef, useState } from 'react';
import PaymentModal from '../Payment.modal';
import { PaymentMethodTransform } from 'helpers/methodTransform';
import { useParams } from 'react-router';
import { getDocumentAndPaymentMadeFile } from 'features/document/Document.api';
import isPayable from 'utils/isPayable';
import isRtoOrder from 'utils/isRtoOrder';
function PaymentMade() {
  const orderLineState = useSelector(selectOrderLine);
  const downloadElement = useRef<HTMLAnchorElement>(null);
  const downloadReceipt = async (paymentMade: PaymentMadeModel) => {
    if (!paymentMade.recieptFileId) {
      return false;
    }
    if (downloadElement.current !== null) {
      const paymentMadeDetail = await getDocumentAndPaymentMadeFile(paymentMade.recieptFileId);
      const url = window.URL.createObjectURL(
        new Blob([paymentMadeDetail], { type: paymentMadeDetail?.type ?? 'application/pdf' }),
      );
      downloadElement.current.target = '_blank';
      downloadElement.current.href = url;
      downloadElement.current.click();
    }
  };
  const paymentMadeItems = useSelector(selectPaymentMades);
  const [open, setOpen] = useState(false);
  const OrderInfo = useSelector(selectOrderLine);
  const { resourceToken = '' } = useParams();
  const [paymentModelKey, setPaymentModelKey] = useState(new Date().getTime());
  useEffect(() => {
    if (resourceToken) {
      setPaymentModelKey(new Date().getTime());
    }
  }, [resourceToken]);
  const columsTable: ColumnsType<PaymentMadeModel> = [
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (value) => <div>{toShortTimezoneBySiteString(value, orderLineState.siteId)}</div>,
      width: '140px',
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
      render: (value) => PaymentMethodTransform(value),
    },
    {
      title: 'Ref Number',
      dataIndex: 'refNumber',
      key: 'refNumber',
      render: (value) => <div>{value}</div>,
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
      render: (value) => <div>{value}</div>,
    },
    {
      title: 'Amount Paid',
      align: 'right',
      dataIndex: 'amount',
      key: 'amount',
      width: '150px',
      render: (value) => (value ? moneyFormat(value) : '–'),
    },
    {
      align: 'center',
      title: 'Receipt',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        if (record.recieptFileId) {
          return (
            <div className='text-center'>
              <EyeIcon
                className='fill-gray-7 hover:fill-gray-5 transition-all hover:cursor-pointer align-middle'
                onClick={(e) => downloadReceipt(record)}
              />
              <a ref={downloadElement}></a>
            </div>
          );
        }
      },
    },
  ];
  return (
    <>
      <TableDesktopWrraper>
        <Table
          rowKey={'id'}
          columns={columsTable}
          dataSource={paymentMadeItems}
          pagination={false}
          summary={() => (
            <>
              <Table.Summary.Row className='font-bold text-gray-7 bg-gray-8'>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align='right'>
                  Total Paid
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='right'>
                  {moneyFormat(paymentMadeItems.reduce((a, b) => a + b.amount, 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
        />
      </TableDesktopWrraper>
      {/* Payment Button */}
      <div className='w-full flex items-center justify-center mt-5'>
        {isPayable(orderLineState) && (
          <Button
            disabled={orderLineState.currentAmountDue === 0 && isRtoOrder(orderLineState)}
            onClick={() => setOpen(true)}
            buttonType='primary'
            className='ml-[102px] px-[30px] py-[13px] rounded-[30px]'>
            MAKE A PAYMENT
          </Button>
        )}
        <PaymentModal
          open={open}
          key={paymentModelKey}
          setOpen={setOpen}
          amountDue={OrderInfo.currentAmountDue ?? 0}></PaymentModal>
      </div>
    </>
  );
}

export default PaymentMade;
