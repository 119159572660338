import environment from 'app/configs/environment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { DEFAULT_DATE_FORMAT } from 'utils/constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const toShortString = (date: Date | string) => {
  const shortTime = dayjs(date).utc().format(DEFAULT_DATE_FORMAT);
  return shortTime;
};

const toShortTimezoneBySiteString = (date: Date | string, site: string) => {
  const timezones = environment.TIMEZONES;
  const timezone = timezones[site];
  const shortTime = dayjs(date).tz(timezone).format(DEFAULT_DATE_FORMAT);
  return shortTime;
};

export { toShortString, toShortTimezoneBySiteString };
