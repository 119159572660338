// eslint-disable-next-line import/no-anonymous-default-export
export default {
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN ?? '',
  AUTH0: {
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
  },
  AUTHORIZE_NET: {
    AUTHORIZENET_ACCEPT_JS_SANDBOX_URL: process.env.REACT_APP_AUTHORIZENET_ACCEPT_JS_SANDBOX_URL ?? '',
    AUTHORIZENET_ACCEPT_JS_PROD_URL: process.env.REACT_APP_AUTHORIZENET_ACCEPT_JS_PROD_URL ?? '',
  },
  TIMEZONES: process.env.REACT_APP_TIMEZONES ? JSON.parse(process.env.REACT_APP_TIMEZONES) : {},
};
